import type {} from "typed-query-selector";
import type { ParseSelector } from "typed-query-selector/parser";

export function certainQuerySelector<E extends ParseSelector<S, HTMLElement>, S extends string>(selectors: S): E {
  const element: E | null = document.querySelector<E>(selectors);
  if (element == undefined) {
    throw new Error("Element not found");
  }
  return element;
}

export function certainQuerySelectorAll<E extends ParseSelector<S, HTMLElement>, S extends string>(
  selectors: S
): NodeListOf<E> {
  const elements: NodeListOf<E> | null = document.querySelectorAll<E>(selectors);
  return elements;
}
