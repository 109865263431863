import { certainQuerySelector, certainQuerySelectorAll } from "./utility";

function clickOnKeyPress(this: HTMLElement, event: KeyboardEvent): void {
  if (event.code === "Enter" || event.code === "Space" || event.keyCode === 13 || event.keyCode === 32) {
    event.preventDefault();
    this.click();
  }
}

for (const element of certainQuerySelectorAll("a")) {
  element.addEventListener("keydown", clickOnKeyPress);
}
for (const element of certainQuerySelectorAll("input[role='button']")) {
  element.addEventListener("keydown", clickOnKeyPress);
}

for (const element of certainQuerySelectorAll("input[type='checkbox'][aria-controls]")) {
  element.addEventListener("change", () => {
    element.setAttribute("aria-expanded", element.checked.toString());
  });
}

const emailElement = certainQuerySelector("a#e");
let emailVisible = false;

function showEmail(): void {
  if (emailVisible) {
    return;
  }
  let emailText = "lorenzo";
  emailText += "@";
  emailText += "woelckner.com";
  emailElement.textContent = emailText;
  emailElement.href = `mailto:${emailText}`;
  emailVisible = true;
}

for (const event of ["focus", "mouseover"]) {
  emailElement.addEventListener(event, () => {
    showEmail();
  });
}
document.addEventListener(
  "selectionchange",
  () => {
    // Trigger when email is selected, but not when the rest of the page is as well
    if (
      document.getSelection()?.containsNode(emailElement, true) === true &&
      document.getSelection()?.containsNode(certainQuerySelector("form")) === false
    ) {
      showEmail();
    }
  },
  { passive: true }
);

const navbar = certainQuerySelector("nav");
const navigationMenuButton = certainQuerySelector("button#navigation-menu-button");
const navigationMenu = certainQuerySelector("div#navigation-menu");
function isNavigationMenuOpen(): boolean {
  return navigationMenuButton.getAttribute("aria-expanded") === "true";
}
function transitionEndInvisibleHandler(event: TransitionEvent): void {
  if (event.target === event.currentTarget && event.propertyName === "opacity") {
    (event.target as HTMLElement).classList.add("invisible");
  }
}
navigationMenuButton.addEventListener("click", (event) => {
  event.stopPropagation();
  navigationMenuButton.setAttribute("aria-expanded", (!isNavigationMenuOpen()).toString());
  navigationMenu.removeEventListener("transitionend", transitionEndInvisibleHandler);
  for (const token of ["max-h-[var(--calc-height)]", "max-h-0", "opacity-0"]) navigationMenu.classList.toggle(token);
  if (isNavigationMenuOpen()) {
    navigationMenu.classList.remove("invisible");
  } else {
    navigationMenu.addEventListener("transitionend", transitionEndInvisibleHandler);
  }
});
navigationMenuButton.addEventListener("keydown", (event) => {
  if (
    isNavigationMenuOpen() &&
    (event.code === "ArrowDown" ||
      // eslint-disable-next-line unicorn/prefer-keyboard-event-key
      event.keyCode === 40 ||
      // eslint-disable-next-line unicorn/prefer-keyboard-event-key
      (!event.shiftKey && (event.code === "Tab" || event.keyCode === 9)))
  ) {
    event.preventDefault();
    navigationMenu.querySelector("a")?.focus();
  }
});
for (const element of navigationMenu.querySelectorAll("a")) {
  element.addEventListener("click", () => {
    if (isNavigationMenuOpen()) {
      navigationMenuButton.click();
    }
  });
  element.addEventListener("keydown", (event) => {
    if (isNavigationMenuOpen()) {
      if (
        event.code === "ArrowUp" ||
        // eslint-disable-next-line unicorn/prefer-keyboard-event-key
        event.keyCode === 38 ||
        // eslint-disable-next-line unicorn/prefer-keyboard-event-key
        (event.shiftKey && (event.code === "Tab" || event.keyCode === 9))
      ) {
        event.preventDefault();
        const previousElement = element.parentElement?.previousElementSibling?.firstElementChild;
        if (previousElement == undefined) {
          navigationMenuButton.focus();
        } else {
          (previousElement as HTMLElement).focus();
        }
        // eslint-disable-next-line unicorn/prefer-keyboard-event-key
      } else if (event.code === "ArrowDown" || event.keyCode === 40) {
        event.preventDefault();
        (element.parentElement?.nextElementSibling?.firstElementChild as HTMLElement | undefined)?.focus();
      }
    }
  });
}
for (const eventType of ["click", "focusin"]) {
  document.addEventListener(eventType as "click" | "focusin", (event) => {
    if (isNavigationMenuOpen() && !navbar.contains(event.target as Element)) {
      navigationMenuButton.click();
    }
  });
}

function getMonthsDifference(startDate: Date, endDate: Date, yearlyRemainerOnly = true): number {
  const monthsDifference =
    endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear());
  return Math.floor(yearlyRemainerOnly ? monthsDifference % 12 : monthsDifference);
}
function getYearsDifference(startDate: Date, endDate: Date): number {
  return Math.round(getMonthsDifference(startDate, endDate, false) / 12);
}

certainQuerySelector("span#current-job-period-years").textContent = getYearsDifference(
  new Date(certainQuerySelector("time#current-job-period-start").dateTime),
  new Date()
).toString();
const monthsDifference = getMonthsDifference(
  new Date(certainQuerySelector("time#current-job-period-start").dateTime),
  new Date()
);
if (monthsDifference > 1) {
  certainQuerySelector("span#current-job-period-months-container").classList.remove("hidden");
  certainQuerySelector("span#current-job-period-months").textContent = monthsDifference.toString();
}
certainQuerySelector("span#current-job-total-years").textContent = getYearsDifference(
  new Date(certainQuerySelector("time#current-job-start").dateTime),
  new Date()
).toString();

const previousSectionButton = certainQuerySelector("button#previous-section-button");
const nextSectionButton = certainQuerySelector("button#next-section-button");
const activeLinkClasses = [
  "text-white",
  "md:hover:text-blue-700",
  "dark:md:hover:text-zinc-300",
  "bg-blue-500",
  "hover:bg-blue-600",
  "rounded-full",
  "md:rounded-none",
  "md:bg-transparent",
  "md:hover:bg-transparent",
  "md:text-blue-500",
  "dark:bg-white",
  "dark:md:bg-transparent",
  "dark:md:text-white",
  "dark:hover:bg-zinc-200",
  "dark:md:hover:bg-transparent",
  "dark:text-zinc-700",
  "duration-300",
];
const inactiveLinkClasses = [
  "text-neutral-700",
  "border-neutral-100",
  "md:hover:text-blue-500",
  "md:dark:hover:text-white",
  "dark:text-zinc-400",
  "dark:border-zinc-700",
  "hover:text-blue-500",
  "dark:hover:text-white",
  "bg-gradient-to-t",
  "bg-right-bottom",
  "hover:bg-left-bottom",
  "bg-no-repeat",
  "bg-[length:0px_1px]",
  "hover:bg-[length:100%_1px]",
  "from-blue-500",
  "to-blue-500",
  "dark:from-white",
  "dark:to-white",
  "duration-500",
  "md:duration-300",
];

const sectionScreenCenterVisibilities = new Map<string, number>();
function getMainSection(entry: IntersectionObserverEntry): string | undefined {
  let newMainSection;
  let newMainSectionVisibility = 0;
  const { id } = entry.target;
  sectionScreenCenterVisibilities.set(id, entry.intersectionRatio);
  const homeSectionVisibility = sectionScreenCenterVisibilities.get("home");
  if (homeSectionVisibility != undefined && homeSectionVisibility > 0.2) {
    newMainSection = "home";
  } else {
    for (const [section, visibility] of sectionScreenCenterVisibilities) {
      if (visibility > newMainSectionVisibility) {
        newMainSection = section;
        newMainSectionVisibility = visibility;
      }
    }
  }
  return newMainSection;
}

if ("IntersectionObserver" in window) {
  let mainSection: string | undefined;
  let previousSectionLink: HTMLAnchorElement | undefined;
  let nextSectionLink: HTMLAnchorElement | undefined;
  previousSectionButton.classList.remove("invisible");
  previousSectionButton.addEventListener("click", () => {
    previousSectionLink?.click();
  });
  nextSectionButton.classList.remove("invisible");
  nextSectionButton.addEventListener("click", () => {
    nextSectionLink?.click();
  });

  const observer = new IntersectionObserver(
    (entries) => {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0 && entry.target.classList.contains("content-auto")) {
          entry.target.classList.add("content-visible");
          entry.target.classList.remove("content-auto");
        }
        const newMainSection = getMainSection(entry);
        if (newMainSection != undefined && newMainSection !== mainSection) {
          mainSection = newMainSection;

          let currentSectionLink = document.querySelector(`a[aria-current]`);
          currentSectionLink?.classList.remove(...activeLinkClasses);
          currentSectionLink?.classList.add(...inactiveLinkClasses);
          currentSectionLink?.removeAttribute("aria-current");
          window.history.replaceState({}, "", mainSection === "home" ? "." : `#${mainSection}`);
          currentSectionLink = certainQuerySelector(`div#navigation-menu a[href="#${mainSection}"]`);
          currentSectionLink.classList.remove(...inactiveLinkClasses);
          currentSectionLink.classList.add(...activeLinkClasses);
          currentSectionLink.setAttribute("aria-current", "page");

          previousSectionLink = currentSectionLink.parentElement?.previousElementSibling?.firstElementChild as
            | HTMLAnchorElement
            | undefined;
          nextSectionLink = currentSectionLink.parentElement?.nextElementSibling?.firstElementChild as
            | HTMLAnchorElement
            | undefined;
          previousSectionButton.disabled = previousSectionLink == undefined;
          nextSectionButton.disabled = nextSectionLink == undefined;
        }
      }
    },
    { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] }
  );

  for (const section of certainQuerySelectorAll("section")) {
    observer.observe(section);
  }
}

const dynamicHeightsElements = certainQuerySelectorAll("div.calc-height");
function calculateDynamicElementsHeights(): void {
  for (const target of dynamicHeightsElements) {
    const height = `${target.firstElementChild?.scrollHeight ?? 0}px`;
    if (target.style.getPropertyValue("--calc-height") !== height) {
      target.style.setProperty("--calc-height", height);
    }
  }
}
window.addEventListener("resize", calculateDynamicElementsHeights, { passive: true });
window.addEventListener("load", calculateDynamicElementsHeights, { passive: true });

function polyfillAudioContextSetTargetAtTime(object: {
  setTargetAtTime?: (target: number, startTime: number, timeConstant: number) => void;
  setTargetValueAtTime?: (target: number, startTime: number, timeConstant: number) => void;
}): void {
  if (object.setTargetAtTime == undefined) {
    object.setTargetAtTime = object.setTargetValueAtTime;
  }
}

function polyfillAudioContext(): void {
  if ("webkitAudioContext" in window && !("AudioContext" in window)) {
    (window as typeof globalThis).AudioContext = (
      window as unknown as {
        webkitAudioContext: typeof globalThis.AudioContext;
      }
    ).webkitAudioContext;

    if (!("createGain" in AudioContext.prototype))
      (AudioContext.prototype as unknown as typeof globalThis.AudioContext.prototype).createGain = (
        AudioContext.prototype as unknown as { createGainNode: typeof globalThis.AudioContext.prototype.createGain }
      ).createGainNode;

    type AugmentedLegacyOscillatorNode = OscillatorNode & {
      noteOn: typeof OscillatorNode.prototype.start;
      noteOff: typeof OscillatorNode.prototype.stop;
    };
    type AugmentedLegacyAudioContext = AudioContext & {
      internalCreateGain: typeof AudioContext.prototype.createGain;
      internalCreateOscillator: () => OscillatorNode;
    };
    // eslint-disable-next-line @typescript-eslint/unbound-method
    (AudioContext.prototype as AugmentedLegacyAudioContext).internalCreateGain = AudioContext.prototype.createGain;
    AudioContext.prototype.createGain = function createGain(this: AugmentedLegacyAudioContext) {
      const gainNode = this.internalCreateGain();
      polyfillAudioContextSetTargetAtTime(gainNode.gain);
      return gainNode;
    };

    (AudioContext.prototype as AugmentedLegacyAudioContext).internalCreateOscillator =
      // eslint-disable-next-line @typescript-eslint/unbound-method
      AudioContext.prototype.createOscillator;
    AudioContext.prototype.createOscillator = function createOscillator(
      this: AugmentedLegacyAudioContext
    ): OscillatorNode {
      const oscillatorNode: Omit<OscillatorNode, "start" | "stop"> = this.internalCreateOscillator();
      if (!("start" in oscillatorNode)) {
        (oscillatorNode as AugmentedLegacyOscillatorNode).start = function start(when?: number) {
          this.noteOn(when ?? 0);
        };
      }
      if (!("stop" in oscillatorNode)) {
        (oscillatorNode as AugmentedLegacyOscillatorNode).stop = function stop(when?: number) {
          this.noteOff(when ?? 0);
        };
      }
      polyfillAudioContextSetTargetAtTime(oscillatorNode.frequency);
      polyfillAudioContextSetTargetAtTime(oscillatorNode.detune);
      return oscillatorNode as OscillatorNode;
    };
  }
}

async function playNote(audioContext: AudioContext, frequency: number, fadeOff: boolean): Promise<void> {
  return new Promise((resolve) => {
    const oscillatorNode = audioContext.createOscillator();
    const gainNode = audioContext.createGain();

    oscillatorNode.addEventListener(
      "ended",
      () => {
        resolve();
      },
      { once: true }
    );
    oscillatorNode.type = "square";
    oscillatorNode.frequency.value = frequency;
    oscillatorNode.connect(gainNode);
    gainNode.gain.value = 0.04;
    gainNode.connect(audioContext.destination);

    oscillatorNode.start(audioContext.currentTime);

    if (fadeOff) {
      gainNode.gain.setTargetAtTime(0, audioContext.currentTime + 0.17, 0.15);
      oscillatorNode.stop(audioContext.currentTime + 2);
    } else {
      gainNode.gain.setTargetAtTime(0, audioContext.currentTime + 0.165, 0.01);
      oscillatorNode.stop(audioContext.currentTime + 0.17);
    }
  });
}

async function playSecretSound(): Promise<void> {
  if ("AudioContext" in window) {
    const audioContext = new AudioContext();
    const frequencies = [784, 740, 622.3, 440, 415.3, 659.3, 830.6, 1047];

    for (const [index, frequency] of frequencies.entries()) {
      await playNote(audioContext, frequency, index + 1 === frequencies.length);
    }
  }
}

function transitionEndHideHandler(event: TransitionEvent): void {
  if (event.target === event.currentTarget && event.propertyName === "opacity") {
    (event.target as HTMLElement).classList.add("hidden");
  }
}

function setupTLDRToggle(): void {
  let tldrModeEnabled = false;
  const tldrToggle = certainQuerySelector("button#tldr-toggle");
  tldrToggle.classList.remove("hidden");
  void tldrToggle.offsetHeight;
  tldrToggle.classList.add("!opacity-100");
  tldrToggle.addEventListener("click", () => {
    tldrModeEnabled = !tldrModeEnabled;
    tldrToggle.setAttribute("aria-pressed", tldrModeEnabled.toString());
    for (const token of [
      "bg-neutral-200",
      "dark:bg-zinc-600",
      "shadow-inner",
      "shadow-neutral-300",
      "dark:shadow-zinc-800",
    ])
      tldrToggle.classList.toggle(token);
    for (const element of certainQuerySelectorAll("[data-dev-remove]")) {
      element.removeEventListener("transitionend", transitionEndHideHandler);
      if (tldrModeEnabled) {
        element.addEventListener("transitionend", transitionEndHideHandler);
      } else {
        element.classList.remove("hidden");
      }
      void element.offsetHeight;
      for (const token of ["decoration-transparent", "opacity-0"]) element.classList.toggle(token);
    }
    for (const element of certainQuerySelectorAll("[data-dev-add]")) {
      element.removeEventListener("transitionend", transitionEndHideHandler);
      if (tldrModeEnabled) {
        element.classList.remove("hidden");
      } else {
        element.addEventListener("transitionend", transitionEndHideHandler);
      }
      void element.offsetHeight;
      element.classList.toggle("!opacity-100");
    }
  });
}

function enableDeveloperMode(): void {
  polyfillAudioContext();
  playSecretSound().catch(() => {
    // Ignore
  });
  certainQuerySelector("aside#developer-mode-hint").classList.add("hidden");
  for (const element of certainQuerySelectorAll("[data-dev-remove]")) {
    element.classList.add("decoration-transparent", "line-through");
    void element.offsetHeight;
    element.classList.add("transition-[opacity,text-decoration-color]", "duration-[2s]");
  }
  for (const element of certainQuerySelectorAll("[data-dev-add]")) {
    element.classList.add("opacity-0", "transition-[opacity]", "duration-[2s]");
  }
  setupTLDRToggle();
}

let developerModeCountdown = 7;
const imageButton = certainQuerySelector("button#image-lorenzo");
const developerModeAlertElement = certainQuerySelector("div#alert-developer-mode");
let developerModeAlertHideTimeout: number | undefined;
imageButton.addEventListener("click", () => {
  --developerModeCountdown;
  if (developerModeCountdown >= 0 && developerModeCountdown < 6) {
    if (developerModeCountdown === 0) {
      developerModeAlertElement.textContent = "You are now a developer!";
      enableDeveloperMode();
    } else {
      developerModeAlertElement.textContent = `You are now ${developerModeCountdown} step${
        developerModeCountdown > 1 ? "s" : ""
      } away from being a developer`;
    }
    developerModeAlertElement.classList.remove("opacity-0");
    clearTimeout(developerModeAlertHideTimeout);
    developerModeAlertHideTimeout = window.setTimeout(() => {
      developerModeAlertElement.classList.add("opacity-0");
      if (developerModeCountdown === 0) {
        developerModeAlertElement.setAttribute("aria-hidden", "true");
      }
    }, 4000);
  }
});

const form = certainQuerySelector("form");
const formInputs = form.querySelectorAll("input,textarea");
const initialsInput = certainQuerySelector("input#initials");
const formSubmitButton = certainQuerySelector("button[type='submit']");
const formStatusElement1 = certainQuerySelector("div#status-form-1");
const formStatusElement2 = certainQuerySelector("div#status-form-2");
const errorFormSubmitButtonClasses = [
  "!border-transparent",
  "!bg-red-600",
  "dark:!bg-red-800",
  "after:!shadow-red-600",
  "dark:after:!shadow-red-800",
];
let submitButtoniterationCount = 0;
if ("onanimationiteration" in formSubmitButton) {
  formSubmitButton.addEventListener("animationiteration", () => {
    ++submitButtoniterationCount;
  });
}
let unicodeRegexSupport;
try {
  // eslint-disable-next-line prefer-regex-literals
  unicodeRegexSupport = new RegExp("\\p{L}", "u").test("a");
} catch {
  unicodeRegexSupport = false;
}
if (!unicodeRegexSupport) {
  certainQuerySelector("input#name").removeAttribute("pattern");
}

function stopButtonAnimation(): void {
  formSubmitButton.classList.remove("after:animate-fadepulse");
}
function setStopButtonAnimationEnabled(enabled: boolean): void {
  if (enabled) {
    submitButtoniterationCount = 0;
  } else if ("onanimationiteration" in formSubmitButton) {
    formSubmitButton.addEventListener(
      "animationiteration",
      () => {
        if (submitButtoniterationCount % 2 === 0) {
          stopButtonAnimation();
        } else {
          formSubmitButton.addEventListener("animationiteration", stopButtonAnimation, { once: true });
        }
      },
      { once: true }
    );
  } else {
    stopButtonAnimation();
  }
}

function formErrorCallback(event: ProgressEvent<XMLHttpRequestEventTarget>, request: XMLHttpRequest): void {
  // eslint-disable-next-line no-console
  console.error("Error submitting form:", event.type, {
    request,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    response: { body: request.response, headers: request.getAllResponseHeaders(), status: request.status },
  });
  setStopButtonAnimationEnabled(false);
  formSubmitButton.textContent = "Failed";
  formSubmitButton.classList.add(...errorFormSubmitButtonClasses);
  formStatusElement2.textContent = "Failed to send message";
  for (const element of formInputs) {
    element.readOnly = false;
  }
  window.setTimeout(() => {
    formStatusElement2.textContent = "";
    formSubmitButton.classList.remove(...errorFormSubmitButtonClasses);
    formSubmitButton.textContent = "Send";
    formSubmitButton.disabled = false;
  }, 4000);
  if (request.status === 400) {
    try {
      const errorObject = JSON.parse(request.responseText) as {
        error: string;
        details?: { fields?: string[] };
      };
      if (
        errorObject.error === "invalid-data-contents" &&
        errorObject.details?.fields != undefined &&
        Array.isArray(errorObject.details.fields)
      ) {
        for (const field of errorObject.details.fields) {
          const formInput = form.querySelector(`input[name=${field}],textarea[name=${field}]`);
          formInput?.setCustomValidity(formInput.title);
        }
        if ("reportValidity" in form) {
          form.reportValidity();
        } else {
          (form as unknown as HTMLFormElement).checkValidity();
        }
      }
    } catch {
      // Ignored
    }
  }
}
function checkValidInputLength(element: HTMLInputElement | HTMLTextAreaElement): void {
  const elementMaxLength = element.getAttribute("maxlength");
  const elementPattern = element.getAttribute("pattern");
  if (
    (element.required && element.value.trim().length === 0) ||
    (elementMaxLength != undefined && element.value.length > Number.parseInt(elementMaxLength, 10)) ||
    // eslint-disable-next-line security/detect-non-literal-regexp
    (elementPattern != undefined && !new RegExp(`^${elementPattern}$`, "u").test(element.value))
  ) {
    element.setCustomValidity(element.title);
  }
}
function formSuccessCallback(): void {
  setStopButtonAnimationEnabled(false);
  formSubmitButton.textContent = "Sent!";
  formSubmitButton.classList.add("!bg-green-600", "!border-transparent", "dark:!bg-green-700");
  formStatusElement2.textContent = "Message successfully sent!";
}
initialsInput.addEventListener("blur", () => {
  if (initialsInput.value.toLowerCase() !== "lw") {
    initialsInput.setCustomValidity(initialsInput.title);
  }
});
for (const input of formInputs) {
  input.addEventListener("input", () => {
    input.setCustomValidity("");
  });
  input.addEventListener("blur", () => {
    input.checkValidity();
  });
}
form.addEventListener("submit", (event) => {
  event.preventDefault();

  for (const input of formInputs) {
    input.setCustomValidity("");
    checkValidInputLength(input);
  }
  // Validate here instead of with stricter pattern to avoid bot form submissions
  if (initialsInput.value.toLowerCase() !== "lw") {
    initialsInput.setCustomValidity(initialsInput.title);
  }
  if (!("reportValidity" in form ? form.reportValidity() : (form as unknown as HTMLFormElement).checkValidity())) {
    return;
  }
  formSubmitButton.disabled = true;
  formStatusElement1.textContent = "";
  formStatusElement1.textContent = "Sending message...";
  formSubmitButton.classList.add("after:animate-fadepulse");
  for (const input of formInputs) {
    input.readOnly = true;
  }
  const request = new XMLHttpRequest();
  request.timeout = 10000;
  request.addEventListener("load", (requestEvent) => {
    if (request.status >= 200 && request.status < 300) {
      formSuccessCallback();
    } else {
      formErrorCallback(requestEvent, request);
    }
  });
  request.addEventListener("error", (requestEvent) => {
    formErrorCallback(requestEvent, request);
  });
  request.addEventListener("timeout", (requestEvent) => {
    formErrorCallback(requestEvent, request);
  });
  request.open(form.method, form.action);
  request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  request.send(
    [...new FormData(form)]
      .map(
        ([key, value]) =>
          // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
          `${encodeURIComponent(key)}=${encodeURIComponent(typeof value === "string" ? value : "")
            // eslint-disable-next-line unicorn/prefer-code-point
            .replace(/[!'()*]/g, (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`)
            .replace(/%20/g, "+")}`
      )
      .join("&")
  );
});

// Delay and fade color change for elements with wrongly reported bad contrast
setTimeout(() => {
  certainQuerySelector("footer").classList.add("text-neutral-400", "dark:text-zinc-600");
  const submitButton = certainQuerySelector("button[type='submit']");
  submitButton.classList.add("border-blue-500", "bg-blue-500");
  submitButton.classList.remove("border-blue-600", "bg-blue-600");
}, 4000);
